import React from "react";

export default ({ error, pastDelay }) => {
	if (error) {
		console.log(error);
		return <div>!</div>;
	} else if (pastDelay) {
		return <div style={{ textAlign: 'center', padding: '20px' }}>Загрузка страницы...</div>;
	} else {
		return null;
	}
};
