import axios from "axios";
import { API_DOMAIN } from "../constants/api";
import { getUserData } from "../redux/actions/user";

const FIVE_DAYS_OLD = 5 * 24 * 60 * 60 * 1000;

class Auth {
  _tokens;

  constructor() {
    const user = getUserData();

    if (isUserObject(user)) {
      this.startSession(user.tokens);
    }
  }

  get accessToken() {
    return this._tokens ? this._tokens.accessToken : "";
  }

  get expiresAt() {
    return this._tokens
      ? new Date(this._tokens.expiresAt)
      : new Date(Date.now() - FIVE_DAYS_OLD);
  }

  get isAuthenticated() {
    return new Date(Date.now() - FIVE_DAYS_OLD) < this.expiresAt;
  }

  loginWithoutRequest(user) {
    this.startSession(user.tokens);
  }

  getResultData(user) {
    return {
      idUser: user.idUser,
      userEmail: user.userEmail,
      userPhone: user.userPhone,
      userName: user.userName,
      userCard: user.userCard,
      userStatus: user.userStatus,
      tokens: {
        accessToken: user.access_token,
        expiresAt: new Date() // JS timestamp in milliseconds
      },
      loggedIn: true // should be set to true by reducer
    };
  }

  login({ username, password }) {
    const request = { username, password, expire_at: new Date() };
    return axios({
      method: "post",
      url: `${API_DOMAIN}users/login`,
      data: request
    }).then(res => {
      const user = res.data || "";
      if (isErrorResponse(user)) {
        throw user;
      }

      const result = this.getResultData(user);

      this.startSession(result.tokens);
      return result;
    });
  }

  forgotPassword({ email }) {
    const request = { email };
    return axios({
      method: "post",
      url: `${API_DOMAIN}users/reset_password`,
      data: request
    }).then(res => {
      if (isErrorResponse(res)) {
        throw res;
      }

      return res;
    });
  }

  logout() {
    this.clearSession();
    return Promise.resolve();
  }

  clearSession() {
    this._tokens = undefined;
  }

  startSession(tokens) {
    this._tokens = tokens;
  }
}

function isUserObject(user) {
  return user && user.hasOwnProperty("tokens") && user.hasOwnProperty("idUser");
}

export function isErrorResponse(res) {
  return res && res.hasOwnProperty("error");
}

const client = new Auth();

export default client;
