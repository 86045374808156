import md5 from "js-md5";
import Auth from "api/auth";
import { clearStorage } from "utils/local-storage";
import { USER_ACTIONS } from "../types";
import { changeView } from "./navigation";
import { apiAction } from "./api";
import { USER } from "../../api/endpoints";
import { defined } from "../../utils/define";
import { loadFromStorage } from "../../utils/local-storage";
import {
  addToBasket,
  addToDatabase,
  clearBasket,
  getCountBasket,
  loadBasketFromDatabase
} from "./basket";
import {
  getCountFavorites,
  loadFavoritesFromDatabase,
  addToDatabase as addFavoritesToDatabase,
  addToFavorites
} from "./favorites";

const userLogIn = user => ({
  type: USER_ACTIONS.LOGIN,
  user
});

const userAddress = address => ({
  type: USER_ACTIONS.ADDRESS,
  address
});

const userIdOrder = idOrder => ({
  type: USER_ACTIONS.ID_ORDER,
  idOrder
});

const userOrders = orders => ({
  type: USER_ACTIONS.SET_ORDERS,
  orders
});

const currentOrder = order => ({
  type: USER_ACTIONS.SET_CURRENT_ORDER,
  order
});

const userLogOut = () => ({
  type: USER_ACTIONS.LOGOUT
});

const userError = error => ({
  type: USER_ACTIONS.ERROR,
  error
});

const onFailure = e =>
  defined(e.response)
    ? defined(e.response.data)
      ? userError(e.response.data)
      : userError(e.response)
    : "Нет связи с сервером, попробуйте позже.";

export const getUserData = () => {
  const store = loadFromStorage();
  return store && store.user && store.user.user;
};

export const getUser = () => dispatch => {
  const user = getUserData();

  return dispatch(
    apiAction({
      requestConfig: USER.GET_USER({ idUser: user.idUser }),
      onSuccess: async data => {
        dispatch(userError([]));
        const userData = Auth.getResultData(data);
        dispatch(userLogIn(userData));
      },
      onFailure: onFailure
    })
  );
};

export const getUserAddress = () => dispatch => {
  const user = getUserData();

  return dispatch(
    apiAction({
      requestConfig: USER.GET_USER_ADDRESS(user.idUser),
      onSuccess: data => userAddress(data),
      onFailure: onFailure
    })
  );
};

export const getUserOrders = idOrder => dispatch => {
  const user = getUserData();

  return dispatch(
    apiAction({
      requestConfig: USER.GET_USER_ORDERS(
        `idUser=${user.idUser}${defined(idOrder) ? `&idOrder=${idOrder}` : ""}`
      ),
      onSuccess: data => {
        if (defined(idOrder)) {
          return currentOrder(data);
        } else {
          return userOrders(data);
        }
      },
      onFailure: onFailure
    })
  );
};

export const getActiveUserOrders = () => dispatch => {
  const user = getUserData();

  return dispatch(
    apiAction({
      requestConfig: USER.GET_USER_ORDERS(`idUser=${user.idUser}&active=1`),
      onSuccess: data => {
        return userOrders(data);
      },
      onFailure: onFailure
    })
  );
};

export const saveUserOrder = (
  data,
  delivery,
  payment,
  basket,
  models
) => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: USER.SAVE_USER_ORDER({
        ...data,
        delivery,
        payment,
        basket,
        models
      }),
      onSuccess: data => {
        if (defined(data.idOrder)) {
          //clearBasket();
          return userIdOrder(data.idOrder);
        } else {
          onFailure(data);
        }

        return false;
      },
      onFailure: onFailure
    })
  );
};

export const userLogin = ({ username, password }) => dispatch =>
  Auth.login({ username: username, password: md5(password) })
    .then(async user => {
      dispatch(userError([]));
      await dispatch(userLogIn(user));

      await dispatch(loadBasketFromDatabase());
      await dispatch(loadFavoritesFromDatabase());

      if (dispatch(getCountBasket()) > 0) {
        dispatch(addToDatabase());
      }
      if (dispatch(getCountFavorites()) > 0) {
        dispatch(addFavoritesToDatabase());
      }

      dispatch(changeView("UserProfile"));
    })
    .catch(e =>
      dispatch(
        userError(
          !!e.response ? e.response : "Нет связи с сервером, попробуйте позже."
        )
      )
    );

export const userRegistration = data => dispatch => {
  let newData = {
    ...data,
    userPasswordHash: md5(data.userPassword)
  };
  delete newData.userPassword;

  return dispatch(
    apiAction({
      requestConfig: USER.ADD_USER(newData),
      onSuccess: async data => {
        dispatch(userError([]));
        const userData = Auth.getResultData(data);
        await Auth.loginWithoutRequest(userData);
        dispatch(userLogIn(userData));
        dispatch(changeView("UserProfile"));
      },
      onFailure: onFailure
    })
  );
};

export const userConfirmPassword = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: USER.CONFIRM_USER(data),
      onSuccess: async data => {
        dispatch(userError([]));
        const userData = Auth.getResultData(data);
        dispatch(userLogIn(userData));
      },
      onFailure: onFailure
    })
  );
};

export const userChangePassword = data => dispatch => {
  let newData = {
    ...data,
    userPasswordHash: md5(data.userPassword)
  };
  delete newData.userPassword;
  delete newData.userNewPassword;

  return dispatch(
    apiAction({
      requestConfig: USER.CHANGE_PASSWORD(newData),
      onSuccess: async data => {
        dispatch(userError([]));
        const userData = Auth.getResultData(data);
        dispatch(userLogIn(userData));
      },
      onFailure: onFailure
    })
  );
};

export const saveUserData = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: USER.SAVE_DATA(data),
      onSuccess: async data => {
        dispatch(userLogIn(data));
      },
      onFailure: onFailure
    })
  );
};

export const userRestorePassword = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: USER.RESTORE_PASSWORD(data),
      onSuccess: async data => {
        dispatch(userError([]));
      },
      onFailure: onFailure
    })
  );
};

export const logout = () => dispatch => {
  Auth.logout().then(async () => {
    await dispatch(userLogOut());
    await clearStorage();
    await dispatch(addToBasket());
    await dispatch(addToFavorites());
  });

  return Promise.resolve();
};
