import { pagesDefault } from "./defaultStates";
import { PAGES_ACTIONS } from "../types";

const pages = (state, action = {}) => {
  switch (action.type) {
    case PAGES_ACTIONS.SET:
      return action.pages;
    case PAGES_ACTIONS.SET_ONE:
      return action.page.idPage ? [...state, action.page] : state;
    default:
      return state;
  }
};

export default (state = pagesDefault, action = {}) => {
  return {
    pages: pages(state.pages, action)
  };
};
