import React, { Component, Fragment } from "react";

import {
  ModalsProvider as Provider,
  ModalsConsumer as Consumer
} from "./context";

import { DEFAULT_MODAL_Z_INDEX } from "constants/modal";
import { ModalProps } from "../../types/modal";
import { AnyObject } from "../../types";

interface Props {
  history: History;
}

interface State extends ModalProps {
  contexts: AnyObject;
  zIndex: number;
}

export class ModalsProvider extends Component<Props, State> {
  state: State = {
    openModal: this.openModal.bind(this),
    closeModal: this.closeModal.bind(this),
    isOpenModal: this.isOpenModal.bind(this),

    setContextModal: this.setContextModal.bind(this),
    getContextModal: this.getContextModal.bind(this),
    contexts: {},
    zIndex: DEFAULT_MODAL_Z_INDEX
  };

  openModal(modalName: string) {
    this.setState(state => ({
      ...state,
      contexts: {
        ...state.contexts,
        [modalName]: {
          ...state.contexts[modalName],
          isOpen: true
        }
      },
      zIndexCounter: state.zIndex + 1
    }));

    return () => this.closeModal(modalName);
  }

  isOpenModal(modalName: string) {
    return (
      !!this.state.contexts[modalName] &&
      !!this.state.contexts[modalName].isOpen
    );
  }

  closeModal(modalName: string) {
    this.setState(state => ({
      ...state,
      contexts: {
        ...state.contexts,
        [modalName]: {}
      },
      zIndexCounter: state.zIndex - 1
    }));
  }

  setContextModal(modalName: string, value: AnyObject) {
    this.setState(state => ({
      ...state,
      contexts: {
        ...state.contexts,
        [modalName]: {
          ...state.contexts[modalName],
          ...value
        }
      }
    }));
  }

  getContextModal(modalName: string) {
    return !!this.state.contexts[modalName]
      ? this.state.contexts[modalName]
      : {};
  }

  render() {
    return (
      <Fragment>
        <Provider value={this.state}>{this.props.children}</Provider>
      </Fragment>
    );
  }
}

interface ModalTriggerProps {
  children: any;
  modalToOpen: string;
  data?: boolean | AnyObject;
}

export const ModalTrigger = ({
  children,
  modalToOpen,
  data = false
}: ModalTriggerProps) => {
  return (
    <Consumer>
      {({ openModal, setContextModal }: ModalProps) => (
        <a
          onClick={e => {
            if (!!data) {
              setContextModal(modalToOpen, {
                contextData: data
              });
            }

            openModal(modalToOpen);
          }}
        >
          {children}
        </a>
      )}
    </Consumer>
  );
};
