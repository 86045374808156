import { basketDefault } from "./defaultStates";
import { BASKET_ACTIONS } from "../types";
import {getModelPhoto} from "../../utils/catalog/modelPhoto";

const basket = (state, action = {}) => {
  switch (action.type) {
    case BASKET_ACTIONS.SET:
      return action.basket;
    default:
      return state;
  }
};

const models = (state, action = {}) => {
  switch (action.type) {
    case BASKET_ACTIONS.SET_MODELS:
      return action.data.map(item => ({
        ...item,
        modelPhoto: getModelPhoto(item)
      }));
    default:
      return state;
  }
};

const messages = (state, action = {}) => {
  switch (action.type) {
    case BASKET_ACTIONS.SET_MESSAGE:
      return action.data;
    default:
      return state;
  }
};

export default (state = basketDefault, action = {}) => {
  return {
    basket: basket(state.basket, action),
    models: models(state.models, action),
    messages: messages(state.messages, action)
  };
};
