import { favoritesDefault } from "./defaultStates";
import { FAVORITES_ACTIONS } from "../types";
import { getModelPhoto } from "../../utils/catalog/modelPhoto";

const favorites = (state, action = {}) => {
  switch (action.type) {
    case FAVORITES_ACTIONS.SET:
      return action.favorites;
    default:
      return state;
  }
};

const models = (state, action = {}) => {
  switch (action.type) {
    case FAVORITES_ACTIONS.SET_MODELS:
      return action.data.map(item => ({
        ...item,
        modelPhoto: getModelPhoto(item)
      }));
    default:
      return state;
  }
};

export default (state = favoritesDefault, action = {}) => {
  return {
    favorites: favorites(state.favorites, action),
    models: models(state.models, action)
  };
};
