import { NAV_ACTIONS } from "../types";

import { sideMenu } from "utils/menu";
import { defined } from "../../utils/define";

const viewChanged = data => {
  return {
    type: NAV_ACTIONS.CHANGED,
    data
  };
};

export const changeView = viewName => (dispatch, getState) => {
  const { navigation } = getState();
  let foundSideMenu;
  sideMenu.forEach(s => {
    if (s.path === viewName || s.view === viewName) {
      foundSideMenu = s;
    } else {
      s.items.forEach(it => {
        if (it.path === viewName || it.view === viewName) {
          foundSideMenu = it;
        }
      });
    }
  });

  if (defined(foundSideMenu)) {
    const { items, view, name } = foundSideMenu;
    const pathname = window.location.pathname;

    let data = {
      sub: items,
      next: view,
      prev: navigation.current,
      prevPath: pathname
    };

    if (navigation.current !== data.next || navigation.prevPath !== pathname) {
      dispatch(viewChanged(data));
    }
  }
  return Promise.resolve();
};

export const getView = viewPath => {
  return sideMenu.reduce((acc, s) => {
    if (s.view === viewPath) {
      return s;
    } else {
      const item = s.items.find(it => it.path === viewPath);
      return !!item ? item : acc;
    }
  }, {});
};
