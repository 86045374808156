import { FAVORITES_ACTIONS } from "../types";
import { loadFromStorage, saveToStorage } from "../../utils/local-storage";
import { defined } from "../../utils/define";
import { apiAction } from "./api";
import { FAVORITES, CATALOG } from "../../api/endpoints";

const setFavorites = favorites => ({
  type: FAVORITES_ACTIONS.SET,
  favorites
});

const setModels = data => ({
  type: FAVORITES_ACTIONS.SET_MODELS,
  data
});

export const onChangeFavorites = favorites => dispatch => {
  return dispatch(setFavorites(favorites));
};

export const getFavoritesModels = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: CATALOG.GET_MODELS({ favorites: data }),
      onSuccess: data => setModels(data),
      onFailure: e => e
    })
  );
};

export const addToFavorites = idModel => dispatch => {
  let favorites = getFavorites();

  if (idModel) {
    const currentItem = favorites.find(item => item.idModel === idModel);

    favorites = defined(currentItem)
      ? favorites.filter(item => item.idModel !== idModel)
      : [
          ...favorites,
          {
            idModel: idModel
          }
        ];

    saveToStorage(favorites, "favorites");

    dispatch(addToDatabase());
  }

  return dispatch(onChangeFavorites(favorites));
};

export const getCountFavorites = () => dispatch =>
  dispatch(() => {
    return getFavorites().length;
  });

export const isFavorites = idModel => dispatch =>
  dispatch(() => {
    return defined(
      getFavorites().find(item => Number(item.idModel) === Number(idModel))
    );
  });

export const getFavorites = () => loadFromStorage("favorites") || [];

const combineFavorites = favoritesData => dispatch => {
  let favorites = getFavorites();
  const favoritesIds = favorites.map(item => item.idModel);

  favoritesData.map(item => {
    if (!favoritesIds.includes(item.idModel)) {
      favorites.push({ idModel: item.idModel });
    }
  });

  saveToStorage(favorites, "favorites");

  return dispatch(onChangeFavorites(favorites));
};

export const loadFavoritesFromDatabase = () => dispatch => {
  let store = loadFromStorage("store") || [];

  if (
    defined(store) &&
    defined(store.user) &&
    defined(store.user.user) &&
    defined(store.user.user.idUser)
  ) {
    return dispatch(
      apiAction({
        requestConfig: FAVORITES.GET(store.user.user.idUser),
        onSuccess: favorites => {
          dispatch(combineFavorites(favorites));
        },
        onFailure: e => e
      })
    );
  }
};

export const addToDatabase = () => dispatch => {
  let favorites = getFavorites();
  let store = loadFromStorage("store") || [];

  if (
    favorites.length > 0 &&
    defined(store) &&
    defined(store.user) &&
    defined(store.user.user) &&
    defined(store.user.user.idUser)
  ) {
    return dispatch(
      apiAction({
        requestConfig: FAVORITES.POST({
          favorites,
          idUser: store.user.user.idUser
        }),
        onSuccess: () => {},
        onFailure: e => e
      })
    );
  }
};
