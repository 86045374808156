import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

import ReduxStore from "./redux";
import Loadable from "react-loadable";
import Loading from "components/loading";
import { ModalsProvider } from "components/modals";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { saveToStorage } from "utils/local-storage";

import "antd/dist/antd.css";
import "scss/index.scss";

(function() {
  var childProcess = require("child_process");
  var oldSpawn = childProcess.spawn;
  function mySpawn() {
    console.log('spawn called');
    console.log(arguments);
    var result = oldSpawn.apply(this, arguments);
    return result;
  }
  childProcess.spawn = mySpawn;
})();

const AppLoadable = Loadable({
  loader: () => import("./app"),
  loading: Loading,
  delay: 0
});

const { store } = ReduxStore;

store.subscribe(() => {
  saveToStorage({
    user: store.getState().user,
    navigation: store.getState().navigation
  });
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ModalsProvider>
        <AppLoadable />
      </ModalsProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("kamcvet")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
