import { catalogDefault } from "./defaultStates";
import { CATALOG_ACTIONS, NEWS_ACTIONS, PAGES_ACTIONS } from "../types";
import { defined } from "../../utils/define";
import {getModelPhoto} from "../../utils/catalog/modelPhoto";

const categories = (state, action = {}) => {
  switch (action.type) {
    case CATALOG_ACTIONS.SET_CATEGORIES:
      return action.categories;
    default:
      return state;
  }
};

const materials = (state, action = {}) => {
  switch (action.type) {
    case CATALOG_ACTIONS.SET_MATERIALS:
      return action.materials;
    default:
      return state;
  }
};

const insets = (state, action = {}) => {
  switch (action.type) {
    case CATALOG_ACTIONS.SET_INSETS:
      return action.insets;
    default:
      return state;
  }
};

const existence = (state, action = {}) => {
  switch (action.type) {
    case CATALOG_ACTIONS.SET_EXISTENCE:
      return action.existence;
    default:
      return state;
  }
};

const sizes = (state, action = {}) => {
  switch (action.type) {
    case CATALOG_ACTIONS.SET_SIZES:
      return action.sizes;
    default:
      return state;
  }
};

const models = (state, action = {}) => {
  switch (action.type) {
    case CATALOG_ACTIONS.SET_MODELS:
      return action.models.map(item => ({
        ...item,
        modelPhoto: getModelPhoto(item)
      }));
    default:
      return state;
  }
};

const newModels = (state, action = {}) => {
  switch (action.type) {
    case CATALOG_ACTIONS.SET_NEW:
      return action.models.map(item => ({
        ...item,
        modelPhoto: getModelPhoto(item)
      }));
    default:
      return state;
  }
};

const model = (state, action = {}) => {
  switch (action.type) {
    case CATALOG_ACTIONS.SET_MODEL:
      return defined(action.model) && defined(action.model[0])
        ? {
            ...action.model[0],
            modelPhoto: getModelPhoto(action.model[0])
          }
        : {};
    default:
      return state;
  }
};

const filters = (state, action = {}) => {
  switch (action.type) {
    case CATALOG_ACTIONS.SET_FILTERS:
      return action.filters;
    default:
      return state;
  }
};

const menuFilters = (state, action = {}) => {
  switch (action.type) {
    case CATALOG_ACTIONS.SET_MENU_FILTERS:
      return action.filters;
    default:
      return state;
  }
};

const count = (state, action = {}) => {
  switch (action.type) {
    case CATALOG_ACTIONS.SET_MODELS_COUNT:
      return action.count;
    default:
      return state;
  }
};

export default (state = catalogDefault, action = {}) => {
  return {
    count: count(state.count, action),
    categories: categories(state.categories, action),
    materials: materials(state.materials, action),
    models: models(state.models, action),
    newModels: newModels(state.newModels, action),
    model: model(state.model, action),
    sizes: sizes(state.sizes, action),
    filters: filters(state.filters, action),
    menuFilters: menuFilters(state.menuFilters, action),
    insets: insets(state.insets, action),
    existence: existence(state.existence, action)
  };
};
