export const METHOD_GET = `get`;
export const METHOD_POST = `post`;
export const METHOD_PUT = `put`;
export const METHOD_DELETE = `delete`;

const protocol = window.location.protocol

export const API_DOMAIN =
  process.env.NODE_ENV === "production"
    ? protocol + "//api.kamcvet.com/"
    : protocol + "//api.kamcvet.com/";
//"http://kamcvet-server/";