import { instagramDefault } from "./defaultStates";
import { INSTAGRAM_ACTIONS } from "../types";

const instagram = (state, action = {}) => {
  switch (action.type) {
    case INSTAGRAM_ACTIONS.SET:
      return action.instagram;
    default:
      return state;
  }
};

export default (state = instagramDefault, action = {}) => ({
  instagram: instagram(state.instagram, action)
});
