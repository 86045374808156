import {
  BannersStore,
  InstagramStore,
  PagesStore,
  NewsStore,
  UserStore,
  CatalogStore,
  BasketStore,
  FavoritesStore
} from "../../types/redux";
import { Users } from "../../models/users";
import Models from "../../models/catalog/models";
import { Address } from "../../models/address";
import { Orders } from "../../models/orders";

export const navDefault = {
  current: "",
  prev: "",
  sub: []
};

export const bannersDefault: BannersStore = {
  banners: [],
  count: 0
};

export const pagesDefault: PagesStore = {
  pages: []
};

export const instagramDefault: InstagramStore = {
  instagram: []
};

export const newsDefault: NewsStore = {
  news: [],
  count: 0
};

export const userDefault: UserStore = {
  idOrder: 0,
  user: {} as Users,
  error: [],
  address: {} as Address,
  orders: [] as Orders[],
  currentOrder: {} as Orders
};

export const catalogDefault: CatalogStore = {
  categories: [],
  materials: [],
  sizes: [],
  models: [],
  newModels: [],
  insets: [],
  existence: [],
  filters: {},
  menuFilters: {},
  count: 0,
  model: {} as Models
};

export const basketDefault: BasketStore = {
  basket: [],
  messages: [],
  models: [] as Models[]
};

export const favoritesDefault: FavoritesStore = {
  favorites: [],
  models: [] as Models[]
};
