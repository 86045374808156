import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";

// --- Utils
import { loadFromStorage } from "utils/local-storage";
import apiMiddleware from "./middlewares/api";

// --- Reducers
import rootReducer from "./rootReducer";

// --- Actions
import { changeView, getView } from "./actions/navigation";

const singleton = Symbol();
const enforcer = Symbol();

class ReduxSingleton {
  constructor(enf) {
    if (enf !== enforcer) {
      throw new Error("Error: Singleton, use .instance to access properties");
    }

    this.persistantState = loadFromStorage();

    this.store = createStore(
      rootReducer,
      this.persistantState,
      applyMiddleware(...[thunk, apiMiddleware])
    );

    this.trackHistory();
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new ReduxSingleton(enforcer);
    }
    return this[singleton];
  }

  trackHistory = () => {
    window.onpopstate = _ => {
      const view = getView(window.location.pathname);
      if (!!view && !!view.view) {
        this.store.dispatch(changeView(view.view));
      }
    };
  };
}

export default ReduxSingleton.instance;
