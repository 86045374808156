import { combineReducers } from "redux";
import { USER_ACTIONS } from "./types";
import banners from "./reducers/banners";
import navigation from "./reducers/navigation";
import pages from "./reducers/pages";
import instagram from "./reducers/instagram";
import news from "./reducers/news";
import user from "./reducers/user";
import catalog from "./reducers/catalog";
import basket from "./reducers/basket";
import favorites from "./reducers/favorites";

const Reducer = combineReducers({
  catalog,
  news,
  banners,
  navigation,
  instagram,
  pages,
  user,
  basket,
  favorites
});

const rootReducer = (state = {}, action = {}) => {
  if (action.type === USER_ACTIONS.LOGOUT) {
    state = { ...state, user: {} };
  }

  return Reducer(state, action);
};

export default rootReducer;
