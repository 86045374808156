import { METHOD_GET, METHOD_POST } from "../constants/api";
import { defined } from "../utils/define";

export const USER = {
  GET_USER: data => ({
    method: METHOD_POST,
    url: `users/get-user`,
    //data
  }),
  SAVE_DATA: data => ({
    method: METHOD_POST,
    url: `users/save-user`,
    data
  }),
  ADD_USER: data => ({
    method: METHOD_POST,
    url: `users`,
    data
  }),
  CONFIRM_USER: data => ({
    method: METHOD_POST,
    url: `users/confirm`,
    data
  }),
  CHANGE_PASSWORD: data => ({
    method: METHOD_POST,
    url: `users/change-password`,
    data
  }),
  RESTORE_PASSWORD: data => ({
    method: METHOD_POST,
    url: `users/restore-password`,
    data
  }),
  GET_USER_ADDRESS: idUser => ({
    method: METHOD_GET,
    url: `users/address?idUser=${idUser}`
  }),
  SAVE_USER_ORDER: data => ({
    method: METHOD_POST,
    url: `users/save-order`,
    data
  }),
  GET_USER_ORDERS: data => ({
    method: METHOD_GET,
    url: `users/orders?${data}`
  })
};

export const BANNERS = {
  GET: (counter, id, page, limit) => ({
    method: METHOD_GET,
    url: `banners${id !== "" ? `/${id}` : ""}${
      counter !== "" ? `?counter=${counter}` : ""
    }${limit !== 0 ? `?page=${page}&limit=${limit}` : ""}`
  }),
  GET_COUNT: () => ({
    method: METHOD_GET,
    url: `banners?get-count=1`
  })
};

export const BASKET = {
  GET: idUser => ({
    method: METHOD_GET,
    url: `basket?idUser=${idUser}`
  }),
  POST: data => ({
    method: METHOD_POST,
    url: `basket`,
    data
  }),
  CHECK: data => ({
    method: METHOD_POST,
    url: `basket/check`,
    data
  })
};

export const FAVORITES = {
  GET: idUser => ({
    method: METHOD_GET,
    url: `favorites?idUser=${idUser}`
  }),
  POST: data => ({
    method: METHOD_POST,
    url: `favorites`,
    data
  })
};

export const PAGES = {
  GET: id => ({
    method: METHOD_GET,
    url: `pages${defined(id) ? `/${id}` : ""}`
  })
};

export const INSTAGRAM = {
  GET: counter => ({
    method: METHOD_GET,
    url: `instagram${counter !== "" ? `?counter=${counter}` : ""}`
  })
};

export const NEWS = {
  GET: (counter, id, page, limit) => ({
    method: METHOD_GET,
    url: `news${id !== "" ? `/${id}` : ""}${
      counter !== "" ? `?counter=${counter}` : ""
    }${limit !== 0 ? `?page=${page}&limit=${limit}` : ""}`
  }),
  GET_COUNT: () => ({
    method: METHOD_GET,
    url: `news?get-count=1`
  })
};

export const CATALOG = {
  GET_MODELS: data => ({
    method: METHOD_POST,
    url: `catalog-models`,
    data
  }),
  GET_MODELS_COUNT: data => ({
    method: METHOD_POST,
    url: `catalog-models`,
    data
  }),
  GET_SIZES: data => ({
    method: METHOD_GET,
    url: `catalogsizes`
  }),
  GET_MATERIALS: data => ({
    method: METHOD_GET,
    url: `catalogmaterials`
  }),
  GET_INSETS: data => ({
    method: METHOD_GET,
    url: `cataloginsets`
  }),
  GET_EXISTENCE: data => ({
    method: METHOD_GET,
    url: `catalogexistence`
  }),
  GET_CATEGORIES: data => ({
    method: METHOD_GET,
    url: `catalogcategories`
  })
};
