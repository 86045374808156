import { newsDefault } from "./defaultStates";
import { NEWS_ACTIONS } from "../types";

const news = (state, action = {}) => {
  switch (action.type) {
    case NEWS_ACTIONS.SET:
      return action.news;
    case NEWS_ACTIONS.SET_ONE:
      return action.news !== null ? [...state, action.news] : state;
    default:
      return state;
  }
};

const count = (state, action = {}) => {
  switch (action.type) {
    case NEWS_ACTIONS.SET_COUNT:
      return Number(action.count);
    default:
      return state;
  }
};

export default (state = newsDefault, action = {}) => {
  return {
    news: news(state.news, action),
    count: count(state.count, action)
  };
};
