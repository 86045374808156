import { navDefault } from "./defaultStates";
import { NAV_ACTIONS } from "../types";
import { AnyAction } from "redux";

export default function navigation(state = navDefault, action: AnyAction) {
  switch (action.type) {
    case NAV_ACTIONS.CHANGED:
      return {
        ...state,
        prev: action.data.prev,
        current: action.data.next,
        sub: action.data.sub
      };
    default:
      return state;
  }
}
