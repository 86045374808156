import { userDefault } from "./defaultStates";
import { USER_ACTIONS } from "../types";
import { UserStore } from "../../types/redux";
import { AnyAction } from "redux";
import { Orders } from "../../models/orders";
import { AnyObject } from "../../types";
import { getModelPhoto } from "../../utils/catalog/modelPhoto";

const auth = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case USER_ACTIONS.LOGIN:
      return {
        ...state,
        ...action.user,
        loggedIn: true
      };
    case USER_ACTIONS.LOGOUT:
      return state;
    default:
      return state;
  }
};

const errors = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case USER_ACTIONS.ERROR:
      return action.error;
    default:
      return state;
  }
};

const address = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case USER_ACTIONS.ADDRESS:
      return action.address;
    default:
      return state;
  }
};

const idOrder = (state = 0, action: AnyAction) => {
  switch (action.type) {
    case USER_ACTIONS.ID_ORDER:
      return action.idOrder;
    default:
      return state;
  }
};

const orders = (state: AnyObject[] = [], action: AnyAction) => {
  switch (action.type) {
    case USER_ACTIONS.SET_ORDERS:
      return action.orders.map((order: AnyObject) => ({
        ...order,
        models: order.models.map((model: AnyObject) => ({
          ...model,
          modelPhoto: model.idModel ? getModelPhoto(model) : []
        }))
      }));
    default:
      return Array.isArray(state) ? state : [];
  }
};

const currentOrder = (state: Orders = {} as Orders, action: AnyAction) => {
  switch (action.type) {
    case USER_ACTIONS.SET_CURRENT_ORDER:
      return action.order.map((order: AnyObject) => ({
        ...order,
        models: order.models.map((model: AnyObject) => ({
          ...model,
          modelPhoto: getModelPhoto(model)
        }))
      }));
    default:
      return state;
  }
};

export default (state = userDefault, action: AnyAction): UserStore => {
  return {
    user: auth(state.user, action),
    error: errors(state.error, action),
    address: address(state.address, action),
    idOrder: idOrder(state.idOrder, action),
    orders: orders(state.orders, action),
    currentOrder: currentOrder(state.currentOrder, action)
  };
};
