import Api from "api/axios";
import { API_CALL } from "../types";
import {
  apiError,
  apiStarted,
  apiEnded,
  accessNotAuthorized
} from "../actions/api";

const apiMiddleware = ({ dispatch }) => next => action => {
  if (!action || !action.type) {
    return;
  }

  if (action.type !== API_CALL) {
    return next(action);
  }

  const { onSuccess, onFailure, label, requestConfig } = action.payload;

  if (label) {
    dispatch(apiStarted(label));
  }

  return Api.request(requestConfig)
    .then(data => dispatch(onSuccess(data)))
    .catch(err => {
      if (
        err.response &&
        err.response.status >= 401 &&
        err.response.status < 404
      ) {
        dispatch(apiError(err));
        dispatch(onFailure(err));
        dispatch(accessNotAuthorized(window.location.pathname));
        return;
      }
      dispatch(apiError(err));
      dispatch(onFailure(err));
    })
    .finally(() => {
      if (label) {
        dispatch(apiEnded(label));
      }
    });
};

export default apiMiddleware;
