/**
 * API ACTION TYPES
 */
export const API_CALL = Symbol("API_CALL");
export const API = {
  ACCESS_DENIED: "API_ACCESS_DENIED",
  ERROR: "API_ERROR",
  STARTED: "API_STARTED",
  ENDED: "API_ENDED"
};

/**
 * NAV ACTION TYPES
 */
export const NAV_ACTIONS = {
  CHANGED: "VIEW_CHANGED"
};

/**
 * USER ACTION TYPES
 */
export const USER_ACTIONS = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  ERROR: "USER_ERROR",
  SET_CODE: "USER_SET_CODE",
  ADDRESS: "USER_SET_ADDRESS",
  ID_ORDER: "USER_SET_ID_ORDER",
  SET_ORDERS: "USER_SET_ORDERS",
  SET_CURRENT_ORDER: "SET_CURRENT_ORDER"
};

/**
 * BANNERS ACTION TYPES
 */
export const BANNERS_ACTIONS = {
  GET: "GET_BANNERS",
  SET: "SET_BANNERS",
  SET_COUNT: "SET_COUNT_BANNERS"
};

/**
 * BASKET ACTIONS TYPES
 */
export const BASKET_ACTIONS = {
  SET: "SET_BASKET",
  SET_MODELS: "SET_MODELS_BASKET",
  SET_MESSAGE: "SET_MESSAGE_BASKET",
};

/**
 * FAVORITES ACTIONS TYPES
 */
export const FAVORITES_ACTIONS = {
  SET: "SET_FAVORITES",
  SET_MODELS: "SET_MODELS_FAVORITES",
};

/**
 * PAGES ACTION TYPES
 */
export const PAGES_ACTIONS = {
  GET: "GET_PAGES",
  SET: "SET_PAGES",
  SET_ONE: "SET_ONE_PAGES"
};

/**
 * INSTAGRAM ACTION TYPES
 */
export const INSTAGRAM_ACTIONS = {
  GET: "GET_INSTAGRAM",
  SET: "SET_INSTAGRAM"
};

/**
 * NEWS ACTION TYPES
 */
export const NEWS_ACTIONS = {
  GET: "GET_NEWS",
  SET: "SET_NEWS",
  SET_ONE: "SET_ONE_NEWS",
  SET_COUNT: "SET_COUNT_NEWS"
};

/**
 * CATALOG ACTION TYPES
 */
export const CATALOG_ACTIONS = {
  SET_CATEGORIES: "SET_CATEGORIES_CATALOG",
  SET_MATERIALS: "SET_MATERIALS_CATALOG",
  SET_SIZES: "SET_SIZES_CATALOG",
  SET_MODELS: "SET_MODELS_CATALOG",
  SET_NEW: "SET_NEW_CATALOG",
  SET_MODELS_COUNT: "SET_MODELS_COUNT_CATALOG",
  SET_MENU_FILTERS: "SET_MENU_FILTERS_CATALOG",
  SET_FILTERS: "SET_FILTERS_CATALOG",
  SET_MODEL: "SET_MODEL_CATALOG",
  SET_INSETS: "SET_INSETS_CATALOG",
  SET_EXISTENCE: "SET_EXISTENCE_CATALOG"
};
