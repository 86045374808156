import { BASKET_ACTIONS } from "../types";
import { loadFromStorage, saveToStorage } from "../../utils/local-storage";
import { defined } from "../../utils/define";
import { apiAction } from "./api";
import { BASKET, CATALOG } from "../../api/endpoints";
import { NavLink } from "react-router-dom";
import React from "react";

const setBasket = basket => ({
  type: BASKET_ACTIONS.SET,
  basket
});

const setModels = data => ({
  type: BASKET_ACTIONS.SET_MODELS,
  data
});

const setMessage = data => ({
  type: BASKET_ACTIONS.SET_MESSAGE,
  data
});

export const getBasketData = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: CATALOG.GET_MODELS({ basket: data }),
      onSuccess: data => setModels(data),
      onFailure: e => e
    })
  );
};

export const onChangeBasket = basket => dispatch => {
  return dispatch(setBasket(basket));
};

export const addToBasket = (
  idModel,
  idSize,
  count,
  params = {}
) => dispatch => {
  let basket = getBasket();

  if (idModel && (idSize || idSize === 0)) {
    const currentItem = basket.find(
      item =>
        Number(item.idModel) === Number(idModel) &&
        Number(item.idSize) === Number(idSize) &&
        JSON.stringify(item.params) === JSON.stringify(params)
    );

    basket = defined(currentItem)
      ? basket
          .filter(
            item =>
              count > 0 ||
              (count === 0 &&
                (Number(item.idModel) !== Number(idModel) ||
                  Number(item.idSize) !== Number(idSize))) ||
              JSON.stringify(item.params) !== JSON.stringify(params)
          )
          .map(item =>
            Number(item.idModel) === Number(idModel) &&
            Number(item.params) === Number(params) &&
            Number(item.idSize) === Number(idSize)
              ? { ...item, count: count }
              : item
          )
      : [
          ...basket,
          {
            idModel: Number(idModel),
            idSize: Number(idSize),
            count: 1,
            params: params
          }
        ];

    saveToStorage(basket, "basket");

    dispatch(addToDatabase());
  }

  return dispatch(onChangeBasket(basket));
};

export const clearBasket = () => {
  saveToStorage([], "basket");
};
export const getCountBasket = (idModel, idSize) => dispatch =>
  dispatch(() => {
    const basket = getBasket();

    if (!!idModel && idSize !== undefined) {
      const currentItem = basket.find(
        item => item.idModel === idModel && item.idSize === idSize
      );
      if (defined(currentItem)) {
        return currentItem.count;
      }

      return 0;
    }

    if (basket) {
      let count = 0;

      basket.forEach(item => {
        count += item.count;
      });

      return count;
    }

    return 0;
  });

export const getBasket = () => loadFromStorage("basket") || [];

const combineBasket = basketData => dispatch => {
  let basket = getBasket();

  const newBasket =
    basket.length > 0
      ? basket.reduce((acc, data) => {
          const oldBasket = basketData.find(
            item =>
              Number(item.idModel) === Number(data.idModel) &&
              Number(item.idSize) === Number(data.idSize) &&
              JSON.stringify(item.params) === JSON.stringify(data.params)
          );

          return [
            ...acc,
            defined(oldBasket) && defined(oldBasket.idModel)
              ? {
                  idModel: Number(oldBasket.idModel),
                  idSize: Number(oldBasket.idSize),
                  params: oldBasket.params,
                  count:
                    Number(data.count) >= Number(oldBasket.basketCount)
                      ? Number(data.count)
                      : Number(oldBasket.basketCount)
                }
              : {
                  idModel: Number(data.idModel),
                  idSize: Number(data.idSize),
                  count: Number(data.count),
                  params: data.params
                }
          ];
        }, [])
      : basketData.map(data => ({
          idModel: Number(data.idModel),
          idSize: Number(data.idSize),
          count: Number(data.basketCount),
          params: data.params
        }));

  saveToStorage(newBasket, "basket");

  return dispatch(onChangeBasket(newBasket));
};

export const loadBasketFromDatabase = () => dispatch => {
  let store = loadFromStorage("store") || [];

  if (
    defined(store) &&
    defined(store.user) &&
    defined(store.user.user) &&
    defined(store.user.user.idUser)
  ) {
    return dispatch(
      apiAction({
        requestConfig: BASKET.GET(store.user.user.idUser),
        onSuccess: basket => {
          dispatch(combineBasket(basket));
        },
        onFailure: e => e
      })
    );
  }
};

export const addToDatabase = () => dispatch => {
  let basket = getBasket();
  let store = loadFromStorage("store") || [];

  if (
    basket.length > 0 &&
    defined(store) &&
    defined(store.user) &&
    defined(store.user.user) &&
    defined(store.user.user.idUser)
  ) {
    return dispatch(
      apiAction({
        requestConfig: BASKET.POST({ basket, idUser: store.user.user.idUser }),
        onSuccess: () => {},
        onFailure: e => e
      })
    );
  }
};

export const checkBasket = () => dispatch => {
  let basket = getBasket();

  if (basket.length > 0) {
    return dispatch(
      apiAction({
        requestConfig: BASKET.CHECK({ basket }),
        onSuccess: data =>
          setMessage(
            data.map(item => {
              dispatch(
                addToBasket(item.idModel, item.idSize, Number(item.quantity))
              );

              return {
                ...item,
                message:
                  Number(item.quantity) === 0
                    ? "Нет в наличии"
                    : "Изенилось доступное количество"
              };
            })
          ),
        onFailure: e => e
      })
    );
  }
};
