import { bannersDefault } from "./defaultStates";
import { BANNERS_ACTIONS } from "../types";

const banners = (state, action = {}) => {
  switch (action.type) {
    case BANNERS_ACTIONS.SET:
      return action.banners;
    default:
      return state;
  }
};

const count = (state, action = {}) => {
  switch (action.type) {
    case BANNERS_ACTIONS.SET_COUNT:
      return Number(action.count);
    default:
      return state;
  }
};

export default (state = bannersDefault, action = {}) => {
  return {
    banners: banners(state.banners, action),
    count: count(state.count, action)
  };
};
