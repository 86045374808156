import { Menu } from "../../types/menu";

export const sideMenu: Menu[] = [
  {
    name: "main",
    items: [
      {
        title: "Главная",
        path: `/`,
        view: "Main"
      },
      {
        title: "Поиск",
        path: `/search/:search`,
        view: "Search"
      },
      {
        title: "Новости",
        path: `/news/:id`,
        view: "News"
      },
      {
        title: "Новости",
        path: `/news`,
        view: "News"
      },
      {
        title: "О нас",
        path: `/pages/:page`,
        view: "Pages"
      },
      {
        title: "Корзина",
        path: `/basket`,
        view: "Basket"
      },
      {
        title: "Оформление заказа",
        path: `/order`,
        view: "Basket",
        private: true
      },
      {
        title: "Избранное",
        path: `/favorites`,
        view: "Favorites"
      },
      {
        title: "Контакты",
        path: `/contacts`,
        view: "Contacts"
      },
      {
        title: "Магазины",
        path: `/shops`,
        view: "Shops"
      }
    ]
  },
  {
    name: "header",
    items: [
      {
        title: "Новинки",
        path: `/new`,
        view: "New"
      },
      {
        title: "Ювелирные изделия",
        path: `/catalog`,
        view: "Catalog",
        items: [
          {
            title: "",
            path: "/catalog/products/:idModel",
            view: "CatalogItem"
          },
          { title: "", path: "/catalog/:category", view: "Catalog" }
        ]
      },
      {
        title: "Акции",
        path: `/promotions`,
        view: "Promotions",
        items: [{ title: "", path: "/promotions/:id", view: "Promotions" }]
      }
    ]
  },
  {
    name: "user",
    items: [
      {
        title: "Авторизация",
        path: `/user/login`,
        view: "UserLogin",
        public: true
      },
      {
        title: "Забыли пароль",
        path: `/user/forgot-pass`,
        view: "UserForgotPass",
        public: true
      },
      {
        title: "Восстановление пароля",
        path: `/user/restore-pass`,
        view: "UserRestorePass",
        public: true
      },
      {
        title: "Регистрация",
        path: `/user/registration`,
        view: "UserRegistration",
        public: true
      },
      {
        title: "Регистрация",
        path: `/user/profile`,
        view: "UserProfile",
        private: true
      },
      {
        title: "Регистрация",
        path: `/user/confirm`,
        view: "UserConfirm"
      },
      {
        title: "Заказ",
        path: `/user/orders/:id`,
        view: "UserOrder"
      },
      {
        title: "Заказы",
        path: `/user/orders`,
        view: "UserOrder"
      }
    ]
  }
];
