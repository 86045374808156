export const loadFromStorage = (item = "store") => {
  try {
    const local_item = getStringFromStorage(item);
    return local_item === null ? undefined : JSON.parse(local_item);
  } catch (e) {
    console.error(e);
    return;
  }
};

export const getStringFromStorage = key => {
  return localStorage.getItem(key);
};

export const saveToStorage = (obj, item = "store") => {
  try {
    const serialized_item =
      obj !== null && typeof obj === "object" ? JSON.stringify(obj) : obj;
    localStorage.setItem(item, serialized_item);
  } catch (e) {
    console.error(e);
    return;
  }
};

export const removeFromStorage = item => {
  try {
    localStorage.removeItem(item);
  } catch (err) {
    console.error(err);
    return;
  }
};

export const saveManyToStorage = tokens => {
  if (Object.keys(tokens).length)
    Object.entries(tokens).forEach(([k, v]) => {
      saveToStorage(v, k);
    });
  return;
};

export const loadManuFromStorage = (...keys) => {
  let res = {};
  if (keys.length) {
    keys.forEach(key => {
      res[key] = loadFromStorage(key);
    });
    return keys;
  }
  return;
};

export const removeManyFromStorage = (...keys) => {
  if (keys.length) {
    keys.forEach(key => {
      removeFromStorage(key);
    });
    return true;
  }
  return false;
};

export const clearStorage = () => {
  localStorage.clear();
};
